<template>
    <div class="main-container">
        <div class="container-content">
     <div class="loading-box">
            <div class="box-top">
                <img src="~@/assets/image/logo.png" alt="logo" class="loading-logo">
            </div>
            <div class="box-middle">
                <p>{{ tipMessage }}</p>
            </div>
            <div class="box-bottom" v-show="loading == true">
                <img src="~@/assets/image/loading.gif" alt="加载中" class="loading-gif">
            </div>
    </div>
        </div>
       
    </div>
</template>
<script>
import { autoLogin } from '@/api/user';
const STUDENT = 1;
const TEACHER = 2;
export default {
    data() {
        return {
            userId:null,
            userToken:null,
            roleType:null,
            lessonId:null,
            schoolId:null,
            liveOrgLessonId:null,
            lessonStartAt:null,
            lessonEndAt:null,
            routerTo:null,
            tipMessage:'登录处理中，请稍后···',
            loading:true
        }
    },
    created(){
        this.userId = this.paramRemoveEmpty(this.$route.query.user_id);
        this.userToken = this.paramRemoveEmpty(this.$route.query.user_token);
        this.roleType = this.paramRemoveEmpty(this.$route.query.auth);
        this.lessonId = this.paramRemoveEmpty(this.$route.query.lesson_id);
        this.schoolId = this.paramRemoveEmpty(this.$route.query.school_id);
        this.liveOrgLessonId = this.paramRemoveEmpty(this.$route.query.live_org_lesson_id);
        this.lessonStartAt = this.paramRemoveEmpty(this.$route.query.lesson_start_at);
        this.lessonEndAt = this.paramRemoveEmpty(this.$route.query.lesson_end_at);
        this.routerTo = this.paramRemoveEmpty(this.$route.query.routerTo);
        if(!this.userId || !this.roleType ||
         !this.lessonId || !this.schoolId){
             this.$notify({
                title: '系统提示',
                message: '资源未找到！',
                type: 'warning'
            });
            return
        }
        this.handleAutoLogin();
        
    },
    methods:{
        paramRemoveEmpty(val){
            return val == "''" ? "" :val
        },
        handleAutoLogin(){
            let _this = this;
            let param = {
                user_id:this.userId,
                user_token:this.userToken,
                auth:this.roleType,
                school_id:this.schoolId,
                lesson_id:this.lessonId,
                live_org_lesson_id:this.liveOrgLessonId,
                lesson_start_at:this.lessonStartAt,
                lesson_end_at:this.lessonEndAt
            };
            autoLogin(param).then( res => {
                if(res.code == 0){
                    _this.tipMessage = '登录成功';
                    _this.loading = false;
                    _this.$notify({
                    title: '系统提示',
                    message: res.message || '登录成功',
                    type: 'success'
                    });
                    let goBackUrl = res.result.goBackUrl;
                    _this.$store.dispatch('LoginUser', res.result);
                    _this.$router.push({path: _this.routerTo, 
                    query: { 
                        auth:_this.roleType ,
                        lesson_id:_this.lessonId,
                        school_id:_this.schoolId,
                        user_id:_this.userId,
                        user_token:_this.userToken,
                        live_org_lesson_id:_this.liveOrgLessonId,
                        lesson_start_at:_this.lessonStartAt,
                        lesson_end_at:_this.lessonEndAt,
                        goBackUrl:goBackUrl
                        }
                    });
                }else{
                    _this.tipMessage = res.message || '登录失败：未知错误';
                    _this.loading = false;
                    _this.$notify({
                        title: '系统提示',
                        message: res.message || '登录失败：未知错误',
                        type: 'warning'
                    });
                }
            })
        }
    }
}
</script>
<style scoped>
.loading-box{
        text-align: center;
        position: relative;
    }
    .loading-logo{
        width: 180px;
    }
    .loading-gif{
        width: 250px;
    }
    .box-top{
        margin-top: 60px;
    }
    .box-middle{
        text-align: center;
        margin: auto;
        margin-top: 200px;
    }
    .box-middle p{
        color: #5F80FF;
        font-size: 25px;
        margin: 0;
    }
    .box-bottom{
        position: absolute;
        top: 180px;
        width: 100%;
    }
    body{
        background-image: url("~@/assets/image/background.png");
        background-repeat:no-repeat;
        background-attachment:fixed;
        background-position:bottom;
    }
</style>